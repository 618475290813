import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'evc-insights',
    template: `
        <!-- subnav here -->
        <router-outlet></router-outlet>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsightsComponent {
    constructor(
    ) { }
}